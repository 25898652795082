import React from "react"
import { graphql } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import Layout from "../components/layout"
import DayModule from "../components/day_module"
import DestinationFacts from "../components/destination_facts"

function Input({ id, label, type, ...rest }) {
  return (
    <div className="mb-6">
      <label htmlFor={ id } className="block mb-2 text-sm text-gray-600 dark:text-gray-400">{ label }</label>
      <input type={ type } name={ id } id={ id } placeholder={ label } { ...rest } required className="w-full px-3 py-2 placeholder-gray-300 border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-bubble-blue-100 focus:border-bubble-blue" />
    </div>
  ) 
}

export default function Destination({ data }) {
  const post = data.markdownRemark
  const image = getImage(post.frontmatter.featuredImage)
  return (
    <Layout>
      <figure className="pt-3 md:pt-6">
        <GatsbyImage image={image} />
        <figcaption className="absolute -mt-20 md:-mt-40 mx-6 md:mx-12 font-heading text-5xl md:text-8xl text-white opacity-90 filter drop-shadow-xl" >
          { post.frontmatter.title }
        </figcaption>
      </figure>

      <div className="md:flex md:flex-row-reverse md:items-start md:space-x-4 md:space-x-reverse pt-3 md:pt-6">
        <DestinationFacts title={ post.frontmatter.title } duration={ post.frontmatter.duration } price={ post.frontmatter.price } />
        <div className="pb-6 font-sans font-light text-lg xl:text-xl text-grey-300 leading-relaxed px-6 md:px-0" dangerouslySetInnerHTML={{ __html: post.html }} />
      </div>

      <div className="md:divide-y-2">
      { data.childPages.edges.map( ({node}) => {
        const destination_image = getImage(node.frontmatter.featuredImage)
        return (
          <DayModule
            title={ `Dag ${ node.frontmatter.sort_order }: ${ node.frontmatter.title }` } 
            text={ node.html }
            image={ destination_image }
          />
        )
      }
      )}
      </div>

      <div className="px-6 py-3 md:py-6 bg-gray-100">
        <h3 className="text-lg pb-3">Ta kontakt for mer informasjon eller for å bestille</h3>
        <form action="https://api.web3forms.com/submit" method="POST" id="form">

          <input type="hidden" name="apikey" value={`${process.env.GATSBY_WEB3FORMS_ACCESS_KEY}`} />
          <input type="hidden" name="subject" value={ `Leads fra turbobla.no: ${ post.frontmatter.title }` } />
          <input type="checkbox" name="botcheck" style={{ display: `none` }} />
          <input type="hidden" name="redirect" value={`${process.env.GATSBY_WEB3FORMS_RECEIPT_URL}`} />
          <input type="hidden" name="destinasjon" value={ post.frontmatter.title } />

          <Input id="name" label="Fullt navn" type="text" />
          <Input id="email" label="E-post" type="text" />
          <Input id="phone" label="Telefon" type="text" />

          <div className="mb-6">
            <button type="submit" className="px-3 py-4 text-white bg-bubble-orange rounded-md focus:outline-none">Send forespørsel</button>
          </div>

        </form>
      </div>

    </Layout>
  )
}

export const query = graphql`
  query($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      frontmatter {
        title
        duration
        price
        featuredImage {
          childImageSharp {
            gatsbyImageData( aspectRatio: 2 )
          }
        }
      }
      html
    }
    childPages:allMarkdownRemark(
      filter: { frontmatter: { parent_page: { eq: "lofoten" } } }
      sort: { fields: [frontmatter___sort_order] }
    ) {
      edges {
        node {
          frontmatter {
						title
            sort_order
            featuredImage {
              childImageSharp {
                gatsbyImageData( aspectRatio: 1.5 )
              }
            }
          }
          html
        }
      }
    }
  }
`
