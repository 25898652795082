import React from "react"

export default function DestinationFacts({ title, duration, price }) {

  return (
    <div className="bg-white shadow overflow-hidden sm:rounded-lg flex-shrink-0 mb-6">
    <div className="bg-gray-50 px-4 py-5 sm:px-6">
      <h3 className="text-lg leading-6 font-medium text-gray-900">
      { title }
      </h3>
      <p className="mt-1 max-w-2xl text-sm text-gray-500">
      Detaljer om turen
      </p>
    </div>
    <div className="border-t border-gray-200">
      <dl>
        <div className="px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
          <dt className="text-sm font-medium text-gray-500">
            Varighet
          </dt>
          <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
            { duration }
          </dd>
        </div>
        <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
          <dt className="text-sm font-medium text-gray-500">
            Pris
          </dt>
          <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
            { price }
          </dd>
        </div>
      </dl>
    </div>
    </div>
  )
}
