import React from "react"
import { GatsbyImage } from "gatsby-plugin-image"

export default function DayModule({ title, text, image, image_desc }) {

  return (
    <div className="md:flex md:space-x-4 py-3 md:mx-0">
      <div className="md:flex-shrink-0 md:w-1/2">
        <GatsbyImage image={image} alt={image_desc} />
      </div>
      <div className="pt-3 xl:pt-0 pl-6">
        <h2 className="font-heading text-2xl xl:text-4xl xl:pb-3">{ title }</h2>
        <div className="xl:text-lg prose" dangerouslySetInnerHTML={{ __html: text }} />
      </div>
    </div>
  )
}
